<template>
  <div class="reserved_badge rounded-pill">
    <button @click="showModal = true" class="btn d-flex align-items-center position-relative p-0">
        <div class="rounded-circle bg-main">
            <i class="far fa-alarm-exclamation text-white p-2 rounded-circle"></i>
        </div>
        <!-- 글자색 추후 결정---- 임시로 style로 지정해둠 -->
        <div class="px-2 pe-2 fw-bold text-main">예약</div>
        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-blue" v-if="reserved_song.length>0">
            {{reserved_song.length}}
        </span>
    </button>
    <b-modal v-model="showModal" hide-header centered >
        <button class="btn text-white bg-transparent close_btn" @click="showModal=false">닫기</button>
        <ul class="modal-scroll">
            <li class="d-flex align-items-center justify-content-between" v-for="(item, index) in reserved_song" :key="index">
                <div class="left text-truncate">
                    <span class="badge bg-primary me-2">{{ index+1 }}</span>
                    <span>{{ item.artist }}</span> - 
                    <span>{{ item.name }}</span>
                </div>
                <div class="right d-flex gap-2 py-3 ms-2">
                    <button class="btn badge border bg-dark" @click="moveForward(index)" variant="info"><i class="fal fa-arrow-up fa-lg"></i></button>
                    <button class="btn badge border text-black" @click="removeReservation(index)"><i class="fal fa-trash fa-lg"></i></button>
                </div>
            </li>
            <li class="text-center" v-if="reserved_song.length==0">
                예약된 노래가 없어요.<br>
                노래를 예약해보세요.
            </li>
        </ul>
       <template #modal-footer>
            <button class="btn bg-blue w-100" :disabled="reserved_song.length==0" @click="play()">노래 시작하기</button>
        </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { mapMutations } from 'vuex';
export default {
    components:{
        BModal
    },
    data(){
        return{
            showModal:false,
            reserved_song:this.$store.state.reserved_song
        }
    },
    methods:{
        ...mapMutations({
            moveForward:'moveForward',
            removeReservation:"removeReservation"
        }),
        play(){
            this.$router.push('/play-ready')
        }
    }
}
</script>

<style lang="scss" scoped>
.reserved_badge{
    outline: none;
    position: fixed;
    bottom: calc(70px + 15px);
    padding: 2px 3px;
    right: 15px;
    background-color: #fff;
    // border: 3px solid #dc3545;
    border: 3px solid #FF5700;
    z-index: 99;
    // color: #dc3545;
    color: #FF5700;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    button{
        outline: none;
        border-color: transparent !important;
    }
}
</style>
<style lang="scss">
.modal{
    .modal-scroll{
        max-height: 400px;
        overflow-y: auto;
    }
    .close_btn{
        position: absolute;
        top: 0px;
        transform: translateY(-100%);
        right: 0;
    }
}
</style>