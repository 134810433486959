import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    show_header_nav: true,
    score_guide: true,
    reserved_song : [],
    favorite_song: [],
    record_song:[
      {
        id:0,
        artist:'Twice',
        name:'MOONLIGHT SUNRISE',
        popularity:32,
        genres:'dance',
        art:'https://musicmeta-phinf.pstatic.net/album/008/744/8744504.jpg?type=r480Fll&v=20230119140947',
        url: require('@/assets/record/TWICE (트와이스) - MOONLIGHT SUNRISE.mp3'),
      },
      {
        id:1,
        type:'7080',
        popularity:60,
        genres:'ballad',
        artist:'왁스(WAX)',
        recommend:true,
        name:'화장을 고치고',
        art:'http://image.genie.co.kr/Y/IMAGE/IMG_ALBUM/015/025/133/15025133_1322534839359_1_600x600.JPG',
        url: require('@/assets/record/태연, 적재 - 화장을 고치고(cover).mp3')
      },
      {
        id:2,
        type:'test',
        popularity:60,
        genres:'test',
        artist:'test',
        recommend:true,
        name:'테스트용 3초 음원',
        art:'http://image.genie.co.kr/Y/IMAGE/IMG_ALBUM/015/025/133/15025133_1322534839359_1_600x600.JPG',
        url: require('@/assets/record/test 5s.mp3')
      }
    ],
    my_song:[
      {
          point: 3000,
          date: '2023-01-20 10:36:25',
          reserved:false,
          favorite:false,
          type:'idol',
          popularity:32,
          genres:'dance',
          id:0,
          artist:'Twice',
          name:'MOONLIGHT SUNRISE',
          art:'https://musicmeta-phinf.pstatic.net/album/008/744/8744504.jpg?type=r480Fll&v=20230119140947'
      },
      {
          point: 3000,
          date: '2023-01-20 10:36:25',
          reserved:false,
          type:'idol',
          popularity:323,
          genres:'dance',
          favorite:false,
          id:1,
          artist: "BTS(방탄소년단)", name: "Life Goes On", art: "https://upload.wikimedia.org/wikipedia/en/7/76/BTS_-_Life_Goes_On_%28Vinyl%29.png"
      },
      {
          point: 3000,
          date: '2023-01-20 10:36:25',
          reserved:false,
          type:'idol',
          favorite:false,
          popularity:90,
          genres:'dance',
          id:2,
          artist: "BLACKPINK", name: "Lovesick Girls", art: "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/680681ea-76ed-4371-8170-ee2d389a49be/de5xtaj-1db9cd37-e970-49b5-be62-4b008a2b268a.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzY4MDY4MWVhLTc2ZWQtNDM3MS04MTcwLWVlMmQzODlhNDliZVwvZGU1eHRhai0xZGI5Y2QzNy1lOTcwLTQ5YjUtYmU2Mi00YjAwOGEyYjI2OGEuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.V3F2RE6InQVjgprAL4UyA2r05h4gqCjIsi_mnM2M5O0"
      },
      {
          point: 3000,
          date: '2023-01-20 10:36:25',
          reserved:false,
          favorite:false,
          type:'idol',
          popularity:25,
          genres:'dance',
          id:3,artist: "EXO", name: "Don't Fight The Feeling", art: "https://upload.wikimedia.org/wikipedia/en/5/5d/Exo_-_Don%27t_Fight_the_Feeling.png"
      },
      {
          point: 3000,
          date: '2023-01-20 10:36:25',
          reserved:false,
          favorite:false,
          type:'idol',
          popularity:167,
          genres:'pop',
          id:4,
          artist: "IU(아이유)", name: "Celebrity", art: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlpfXw9IHfJf48qYcNRaQ9eCaQy8V2rSHaOMPo6KETYQ&s"
      },
    ],
    all_songs:[
      {
          reserved:false,
          favorite:false,
          type:'idol',
          popularity:32,
          genres:'dance',
        recommend:true,
        id:0,
          artist:'Twice',
          name:'MOONLIGHT SUNRISE',
          art:'https://musicmeta-phinf.pstatic.net/album/008/744/8744504.jpg?type=r480Fll&v=20230119140947'
      },
      {
          reserved:false,
          type:'idol',
          popularity:323,
        recommend:true,
        genres:'dance',
          favorite:false,
          id:1,
          artist: "BTS(방탄소년단)", name: "Life Goes On", art: "https://upload.wikimedia.org/wikipedia/en/7/76/BTS_-_Life_Goes_On_%28Vinyl%29.png"},
      {
          reserved:false,
          type:'idol',
          favorite:false,
        recommend:true,
        popularity:90,
          genres:'dance',
          id:2,
          artist: "BLACKPINK", name: "Lovesick Girls", art: "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/680681ea-76ed-4371-8170-ee2d389a49be/de5xtaj-1db9cd37-e970-49b5-be62-4b008a2b268a.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzY4MDY4MWVhLTc2ZWQtNDM3MS04MTcwLWVlMmQzODlhNDliZVwvZGU1eHRhai0xZGI5Y2QzNy1lOTcwLTQ5YjUtYmU2Mi00YjAwOGEyYjI2OGEuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.V3F2RE6InQVjgprAL4UyA2r05h4gqCjIsi_mnM2M5O0"},
      {
          reserved:false,
          favorite:false,
        recommend:false,
        type:'idol',
          popularity:25,
          genres:'dance',
          id:3,artist: "EXO", name: "Don't Fight The Feeling", art: "https://upload.wikimedia.org/wikipedia/en/5/5d/Exo_-_Don%27t_Fight_the_Feeling.png"},
      {
          reserved:false,
          favorite:false,
          type:'idol',
        recommend:false,
        popularity:167,
          genres:'pop',
          id:4,
          artist: "IU(아이유)", name: "Celebrity", art: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlpfXw9IHfJf48qYcNRaQ9eCaQy8V2rSHaOMPo6KETYQ&s"},
      {
          reserved:false,
        recommend:false,
        favorite:false,
          type:'idol',
          popularity:32,
          genres:'dance',
          id:5,
          artist: "TOMORROW X TOGETHER", name: "Magic Island", art: "https://upload.wikimedia.org/wikipedia/en/1/1f/TXT_-_Still_Dreaming_digital_cover.png"},
      {
          reserved:false,
          favorite:false,
        recommend:false,
        type:'idol',
          popularity:428,
          genres:'dance',
          id:6,
          artist:'뉴진스',
          name:'Ditto',
          art:'https://musicmeta-phinf.pstatic.net/album/008/500/8500274.jpg?type=r480Fll&v=20230111101824'
      },
      {
          reserved:false,
          favorite:false,
        recommend:false,
        type:'trend',
          popularity:620,
          genres:'dance',
          id:7,
          artist:'윤하',
          name:'사건의 지평선',
          art:'https://musicmeta-phinf.pstatic.net/album/007/434/7434553.jpg?type=r480Fll&v=20230109102326'
      },
      
      {
          reserved:false,
          favorite:false,
        recommend:false,
        type:'7080',
          id:8,
          artist:'가수',
          name:'임의의 제목',
          art:'https://placehold.co/150x150/3e01ff/white'
      },
      
      {
          reserved:false,
          favorite:false,
        recommend:false,
        type:'kids',
          id:9,
          artist:'가수2',
          name:'제목33',
          art:'https://placehold.co/150x150/3e01ff/white'
      },
      
      {
          reserved:false,
          favorite:false,
        recommend:false,
        type:'kids',
          id:10,
          artist:'핑크퐁',
          name:'아기상어',
          art:'https://placehold.co/150x150/3e01ff/white'
      },
      {
          reserved:false,
          favorite:false,
        recommend:false,
        type:'7080',
          popularity:60,
          genres:'ballad',
          id:11,
          artist:'왁스(WAX)',
          name:'화장을 고치고',
          art:'http://image.genie.co.kr/Y/IMAGE/IMG_ALBUM/015/025/133/15025133_1322534839359_1_600x600.JPG'
      }
    ],
    login: true,
    my_info:{
      nick: '닉네임',
      email: 'example@gmail.com',
      avatar:'https://www.placeholder.com/150x150'
    },
    alert_list:[
      {
        idx:0,
        thumbnail:'https://musicmeta-phinf.pstatic.net/album/008/744/8744504.jpg?type=r480Fll&v=20230119140947',
        title:'트와이스 신곡 출시',
        desc:`Twice - moonrise, sunrise 업데이트! 지금 번다싱어에서 불러볼까요?`
      }
    ]
  },
  plugins: [createPersistedState()],
  mutations: {
    scoreGuide(state, value){
      state.score_guide = value;
    },
    loadSong(state,value){
      state.all_songs = value;
    },
    show_header_nav(state, value){
      state.show_header_nav = value;
    },
    // 예약 함수
    reservation(state, song){
      song.reserved=true;
      state.reserved_song.push(song);
    },
    moveForward(state, index) {
      if (index > 0 && index < state.reserved_song.length) {
        const temp = state.reserved_song[index];
        state.reserved_song.splice(index, 1);
        state.reserved_song.splice(index - 1, 0, temp);
      }
    },
    addFavorite(state, song){
      song.favorite=true;
      state.favorite_song.push(song);
    },
    removeFavorite(state, index) {
      state.favorite_song[index].favorite=false;
      state.favorite_song.splice(index, 1);      
    },
    removeReservation(state, index) {
      state.reserved_song[index].reserved=false;
      state.reserved_song.splice(index, 1);      
    },
    removeRecord(state, index){
      state.record_song.splice(index, 1);      
    },
    removeAlert(state, index){
      state.alert_list.splice(index, 1);      
    }
  },
  actions: {
    reservation({commit}, song){
      commit('reservation',song)
    }
  },
  modules: {
  }
})
