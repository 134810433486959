<template>
    <div class="header_wrap position-relative">
        <header class="title_header hstack justify-content-between px-3 position-fixed top-0 start-0 w-100" :class="{'on' : has_header_bg}">
            <div>
                <!-- <button @click="ClickEvent()" class="btn ">
                    <i class="fal fa-chevron-left"></i>
                </button> -->
                <i class="fas fa-user-circle fs-px-28 text-gray-500" v-if="!hide_profile"></i>
            </div>
            <div class="tit fw-bold text-truncate fs-px-20">{{title}}</div>
            <router-link to="/">
                <i class="fas fa-home fs-px-23 text-gray-600"></i>
            </router-link>
        </header>
    </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default:'',
        },
        link:{
            type:String,
            default:'',
        },
        hide_profile:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            has_header_bg: false
        }
    },
    methods: {
        ClickEvent(){
            if( this.link == '' ){
                this.goBack();
            }else{
                this.goLink();
            }
        },
        goBack(){
            this.$router.go(-1);
        },
        goLink(){
            this.$router.push(this.link);
        },
        HeaderBg(){
            if(window.scrollY > 60){
                this.has_header_bg = true;
            }else{
                this.has_header_bg = false;
            }
        }
    },
    mounted(){
        document.addEventListener('scroll', this.HeaderBg);
    },
    destroyed(){
        window.removeEventListener('scroll', this.HeaderBg);
    },

}
</script>

<style lang="scss" scoped>
    .header_wrap{
        padding-bottom: 60px;
        z-index: 99;
    }

    .title_header{
        transition: all 0.2s;

        &.on{
            background-color: #ffffff;
        }
    }
</style>