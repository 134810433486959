<template>
  <nav id="Nav" class="bg-body position-fixed bottom-0 w-100" v-if="show">
      <ul class="d-flex justify-content-around align-items-center py-2">
          <li><router-link to="/my-assets/user">
              <div class="lh-1 fs-2">
                <i class="fal fa-coins"></i>
              </div>
              <span class="fs-px-12">MY 자산</span>
          </router-link></li>
          <li><router-link to="/signin">
              <div class="lh-1 fs-2">
                <i class="fas fa-sign-in-alt"></i>
              </div>
              <span class="fs-px-12">로그인</span>
          </router-link></li>
          <li>
            <button class="btn home_icon fs-4 rounded-circle mx-auto" @click="$router.push('/play-ready')">
                  <i class="fas fa-play ms-1 mt-1"></i>
                  <!-- <img src="@/assets/img/icon/home3.svg" alt="home" width="20"> -->
            </button>
          </li>
          <li><router-link to="/mission-bank">
              <div class="lh-1 fs-2">
                <i class="far fa-piggy-bank"></i>
              </div>
              <span class="fs-px-12">미션뱅크</span>
          </router-link></li>
          <li><router-link to="/">
              <div class="lh-1 fs-2">
                  <i class="fal fa-plus"></i>
              </div>
              <span class="fs-px-12">예약곡 등록</span>
          </router-link></li>
      </ul>
    </nav>
</template>

<script>
export default {
  data(){
    return{
      show: true,
    }
  },
  created() {
    this.$EventBus.$on('HideNav', () => {
      this.show = false;
    }),
    this.$EventBus.$on('ShowNav', () => {
      this.show = true;
    })
  },
}
</script>

<style lang="scss" scoped>
#Nav{
    // max-width: 768px;
    z-index: 99;
    box-shadow: 0 0 7px 3px #00000017;
    // max-width: 100vw;

    ul{
        // height: 60px;
        /* border-width: 3px !important;
        border-radius: 15px !important;
        box-shadow: 0 0px 20px rgba(125, 125, 125, 0.1);*/
        li{
            width: 20%;
            line-height: 1;
            text-align: center;

            a{
                opacity: 0.8;
            }

            a.router-link-active{
                opacity: 1;
            }

            .home_icon{
              color: #333 !important;
              border: 3px solid #333;
              font-size: 15px;
              width: 60px;
              height: 60px;
              padding: 0 !important;
              display: flex;
              align-items: center;
              justify-content: center;
            }
        }
    }
    
}
</style>