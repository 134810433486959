<template>
  <div id="app">
    <div class="wrap">
      <transition name="fade">
        <Loading v-if="introCheck"></Loading>
      </transition>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/common/Loading.vue'
export default {
  components:{
      Loading,
  },
  data(){
    return{
      introCheck:true,
    }
  },
  mounted(){
    setTimeout(() => {
      this.introCheck=!this.introCheck
    }, 1000);
  },
}
</script>
<style lang="scss">
// @import 'assets/css/reset.css'; //reset
@import 'assets/css/all.css'; //폰트어썸
@import '~bootstrap/dist/css/bootstrap.min.css'; //부트스트랩
@import '~bootstrap-vue/dist/bootstrap-vue.css'; //부트스트랩
@import 'assets/css/custom-bootstrap.scss';

// font 프리텐다드, HS (KR) Poppins (EN)
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/variable/pretendardvariable-dynamic-subset.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@font-face {
    font-family: 'HS-Regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/HS-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
#app{
  width: 100%;
  overflow: hidden;
  // 문제 발생 시 지울것 (320px 해상도 스크롤 제거 목적)
  position: relative;
  // background-color: #2c2d30;
  margin: 0 auto;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic Neo", 'Poppins', "Pretendard Variable", Pretendard, "Noto Sans KR", "Segoe UI", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
img{
  -webkit-user-drag: none;
}
.wrap{
  // background-color: #2c2d30;
  min-height: 100vh;
  // max-width: 1024px;
  color: #333;
  margin: 0 auto;
}

.title_header{
  height: 60px;
}
.pt-header{
  padding-top: 75px;
}
.pb-nav{
  padding-bottom: 70px;
}
.pb-res{
  padding-bottom: 50px;
}
.fade-enter-active {
  transition: all .5s ease;
}
.fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-leave-to {
  opacity: 0;
}

.invert-1{
  filter: invert(1);
}
.basic_list{
  >li:last-child{
    margin-bottom: 0 !important;
  }
  .left{
    max-width: calc(90vw - 80px);
      .album_info{ 
        width: 100%;
      }
  }
  .right{
      max-width: 80px;
      width: 80px;
      justify-content: flex-end;

      .btn{
        text-align: center;
        line-height: 33px;
        width: 33px;
        height: 33px;
        padding: 0;
        // background-color: #2c2d30 !important;
        //box-shadow: rgba(0, 0, 0, 40%) 0px 2px 4px, rgba(0, 0, 0, 30%) 0px 7px 13px -3px, #1a1a1a 0px -3px 0px inset;
      }
  }
}
// 실시간 검색어 창 css
.keyward{
  height: 55px;  
}
.real_time_list{
    z-index: 20;
    top: 53px;
    padding: 15px 22px;
    background-color: #fff;
    width: calc(100% + 1px);
    left: -1px !important;
    border-right: none !important;

  li{
      display: flex;
      margin-bottom: 10px;
      font-size: 13px;
  }
  li span.badge{
      margin-right: 13px;
      flex-shrink: 0;
      width: 22px;
      text-align: center;
      padding: 6px 0;
  }
}

.infoPp{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}
.infoPp .dimm{
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  animation: fadeIn 0.4s;
}
.infoPp .content{
  position: fixed;
  background-color: #fff;
  padding: 15px;
  border-radius: 15px 15px 0 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1024px;
  max-height: 75vh;
  overflow: auto;
  margin-left: -1px;
  animation: slideUp 0.2s;
}
@keyframes slideUp{
  from {
      bottom: -100%;
  }

  to {
      bottom: 0;
  }
}
@keyframes fadeIn{
  from {
      background-color: rgba(0,0,0,0);
  }

  to {
      background-color: rgba(0,0,0,0.3);
  }
}

.sign_box{
    max-width: 300px;
    margin: 0 auto;
}

.line_tab{
  ul{
    display: flex;
    justify-content: space-between;
    width: 100%;

    li{
      width: 100%;
      text-align: center;
      border-bottom: 2px solid;
      opacity: 0.4;
      border-color: rgba(255, 255, 255, 0.3);

      &.on{
        opacity: 1;
        border-color: rgb(255, 255, 255);
      }

      a{
        display: block;
        width: 100%;
        height: 100%;
        padding: 14px 0;
      }
    }
  }
}



.sns_box, .sign_box{
    max-width: 320px;
    margin: 0 auto;
}

.sns_box{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 0%);
}

.sns_apple{
    background-color: #222;
}
.sns_facebook{
    background-color: #0d7ec4;
}
.sns_naver{
    background-color: #00c73c;
}
.sns_kakao{
    background-color: #ffeb3b;
}

.empty_data{
  p{
    color: #aaa;
  }
}

.alert{
  z-index: 100;
}

// 공통 헤더
.category {
  padding-top: 15px;
  .btn{
      width: 55px;
      height: 55px;
      font-size: 19px;
      padding: 5px !important;
      font-weight: 600;
      text-align: center;
  }
}
.search_filter{
  button{
      border: none;
      border-radius: 0;
      background-color: rgb(40,40,40);
      color: #939393;
      &.active{
          background-color: #1e1f21;
          color: #fff;
      }
      &:nth-of-type(2){
          border-right: 1px solid;
          border-left: 1px solid;
          border-color: inherit;
      }
  }
}
.search_input{
  padding: 0 15px;
  box-sizing: border-box;
}
.input-group{
  z-index: 3;
  //color: #000 !important;
  height: 50px;
  input{
      border-color: inherit;
  }
}
.input-autocomplete{
  position: absolute;
  left: 0;
  border-radius: 0 0 18px 18px;
  height: 270px;
  width: 100%;
  z-index: 2;
  padding-top: 25px;
  box-shadow: 0 0 3px black;
}
.input-group-text{
  // color: #fff;
  background-color: transparent;
  border-color: inherit;
  border: none;
  color: #FF5700;
}

// 눌린 버튼
.basic_list .right .btn.checked{
  // background-color: #141414 !important;
  color: #78a6e9 !important;
  /* text-shadow: 4px 3px 7px #6abed3; */
  /* border: none !important; */
  /* font-weight: 600 !important; */
  box-shadow: #3f51b500 3px 3px 6px 0px inset;
}
// galaxy fold 320 해상도 이하 기준
@media screen and (max-width:321px) {
  .basic_list{
      >li{
        border-bottom: 1px solid #eee;
        padding-bottom: 1em;
      }
      .left{
          max-width:calc(90vw - 40px);
      }
      .left .album_info{
          max-width: 100%;
      }
      .right{
          flex-direction: column;
          max-width: 40px;
          padding: 0 !important;
      }
  }
  .real_time_list{
    padding: 10px 15px;
  }
  .search .search_filter button{
    font-size: smaller;
  }
}
</style>
