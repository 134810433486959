import Vue from 'vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import { BModal } from 'bootstrap-vue'
import { BAlert } from 'bootstrap-vue'

import App from './App.vue'


Vue.use(BootstrapVue)

Vue.component('b-modal', BModal)
Vue.component('b-alert', BAlert)
Vue.config.productionTip = false

Vue.prototype.$EventBus = new Vue();

new Vue({
  router,
  store,
  BootstrapVue,
  render: h => h(App)
}).$mount('#app')
