<template>
    <div class="search pb-nav">
        <Header title="곡 검색"></Header>
        <!-- <Reservation></Reservation> -->
        <div class="search_input position-relative bg-dark">
            <div class="d-flex category justify-content-between mb-2">
                <button class="btn rounded-circle bg-body"><i class="fas fa-microphone"></i></button>
                <button class="btn rounded-circle bg-body fs-px-14" @click="$router.push('/list/recent')">NEW</button>
                <button class="btn rounded-circle bg-main"><i class="fas fa-search"></i></button>
                <button class="btn rounded-circle bg-body" @click="$router.push('/favorite')"><i class="fas fa-star"></i></button>
                <button class="btn rounded-circle bg-body"><i class="fas fa-plus"></i></button>
            </div>
            <div class="input-group rounded-pill">
                <span class="input-group-text px-1" id="basic-addon1"><i class="far fa-search"></i></span>
                <input type="search" class="form-control bg-transparent text-white border-0" placeholder="검색어를 입력하세요" aria-label="Username" aria-describedby="basic-addon1" v-model="search" v-on:keyup.enter="searchFn()">
                <span class="input-group-text px-1" @click="autocomplete=!autocomplete"><i class="far fa-caret-down" :class="{'fa-caret-up':autocomplete}"></i></span>
                <!-- <span class="input-group-text"><i class="far fa-times"></i></span> -->
            </div>
            <!-- 검색 내력 -->
            <div class="input-autocomplete bg-dark" v-if="autocomplete">
                <ul v-if="search_history.length>0" class="py-2">
                    <li v-for="(item, index) in search_history" :key="index" class="d-flex justify-content-between py-2 mx-4  align-items-center text-muted">
                        <small class="">{{ item }}</small>
                        <i class="fal fa-trash fa-sm" @click="deleteHistory(index)"></i>
                    </li>
                </ul>
                <div v-else class="d-flex align-items-center justify-content-center h-100 flex-column text-muted"><i class="fas fa-headphones-alt fa-4x mb-3 text-muted"></i>검색한 음악이 없습니다.</div>
            </div>
        </div>
        
        <div class="search_result">
            <!-- <div class="search_filter d-flex m-3 mb-0 border">
                <button class="btn text-center col-4" :class="{'active':search_filter===0}" @click="filterFn(0)">통합검색</button>
                <button class="btn text-center col-4" :class="{'active':search_filter===1}" @click="filterFn(1)">제목 검색</button>
                <button class="btn text-center col-4" :class="{'active':search_filter===2}" @click="filterFn(2)">가수 검색</button>
            </div> -->
            <div v-if="filteredList.length>0">
                <BasicList :list="filteredList" class="pb-res"></BasicList>
            </div>
            <div class="empty_data py-3" v-else>
                <p class="text-center pt-5">
                    찾으시는 노래가 없나요?
                    <br>
                    가수, 제목을 정확히 입력하셨나요?
                </p>
            </div>
        </div>
        
        <div class="alert alert-success d-flex align-items-center position-fixed start-50 translate-middle-x shadow bottom-0 mb-2" :class="{'alert-danger':alertType==='C'}" style="width:95%" role="alert"
            v-if="showAlert">
            <i class="fas fa-check me-2"></i> 
            <div v-if="alertType2==='reservation'">
                {{alertType==='C'?'노래를 취소하였습니다':'노래를 예약하였습니다'}}
            </div>
            <div v-else>
                {{alertType==='C'?'즐겨찾기에서 삭제되었습니다':'즐겨찾기에서 추가되었습니다'}}
            </div>
        </div>

        <Nav></Nav>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import Reservation from '@/components/common/Reservation.vue'
import TitleHeader from '@/components/common/TitleHeader.vue'

import Header from '@/components/common/Header.vue'
import Nav from '@/components/common/Nav.vue'

export default {
    components:{
        Header,Nav,
        BasicList, Reservation, TitleHeader,
    },
    data(){
        return{
            title: '검색',
            showAlert: false,
            alertType:'',
            alertType2:'',
            search:'',
            search_filter:0,
            autocomplete:true,
            search_history:[],
        }
    },
    methods:{
        searchFn(){
            // pattern 공백체크
            var pattern = /\s/g; 
            if(this.search !== (null || '') && !this.search.match(pattern)){
                // 검색이력 6개 이상 넘어가면 초기 검색값 지우고 추가
                if(this.search_history.length>5 ){
                    this.search_history.shift();
                }
                // 이미 검색기록에 있는 키워드면 패스, 아니면 이력 추가
                if(!this.search_history.includes(this.search)){
                    this.search_history.push(this.search)
                }
            }
            // search하면 검색내역은 안보이게 false
            this.autocomplete = false;
        },
        filterFn(idx){
            this.search_filter=idx;
        },
        deleteHistory(index){
            this.search_history.splice(index,1)
        },
    },
    computed:{
        song_list(){
            return this.$store.state.all_songs
        },
        reservation_list(){
            return this.$store.state.reserved_song
        },
        favorite_list(){
            return this.$store.state.favorite_song
        },
        filteredList() {
            if(this.search_filter===1){
                return this.song_list.filter(song => {
                    return song.name.toLowerCase().includes(this.search.toLowerCase())
                })
            }else if(this.search_filter===2){
                return this.song_list.filter(song => {
                    return song.artist.toLowerCase().includes(this.search.toLowerCase())
                })
            }else{
                return this.song_list.filter(song => {
                    return song.name.toLowerCase().includes(this.search.toLowerCase())||song.artist.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        }
    },
    mounted(){
        this.searchFn();
    },
    destroyed(){
    }
}
</script>

<style lang="scss" scoped>
</style>