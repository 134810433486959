<template>
  <div class="basic_set_list">
    <ul class="basic_list d-flex flex-wrap flex-column">
      <!-- <li v-for="(item,index) in list" :key="index" class="d-flex justify-content-between pb-3"> -->
      <li v-for="(item,index) in list" :key="index" class="d-flex justify-content-between border-bottom p-3" :class="{'bg-select':index===selectSong}" @click="selectSong===index?selectSong=null:selectSong=index">
          <div class="left d-flex flex-grow-1">
              <!-- <div class="align-self-center me-3 bg-black p-2 rounded-circle text-center flex-shrink-0" style="height:40px;width:40px"  @click="$router.push('/play-ready')">
                <i class="fas fa-play"></i>
              </div> -->
              <!-- <img :src="item.art" class="border ratio ratio-1x1 img-fluid" alt="album art" style="width:70px;"> -->
              <div class="album_info d-flex flex-column justify-content-center pe-3">
                  <span class="text-truncate">{{ item.name }}
                    <span class="badge border border-primary text-primary" v-if="item.recommend">추천</span>
                  </span>
                  <small class="text-truncate text-opacity-50 text-body">{{ item.artist }}</small>
              </div>
          </div>
          <!-- <div class="right d-flex gap-2 py-3">
              <button class="btn badge"  @click="delayedAlert(item,true)" :class="{'checked':item.reserved}" variant="info"><i class="fal fa-clock fa-lg" :class="{'fas':item.reserved}"></i></button>
              <button class="btn badge" :class="{'checked':item.favorite}" @click="delayedAlert(item,false)"><i class="fal fa-star fa-lg" :class="{'fas':item.favorite}"></i></button>
          </div> -->
      </li>
    </ul>
    <div class="alert alert-success d-flex align-items-center position-fixed start-50 translate-middle-x shadow bottom-0 mb-2" :class="{'alert-danger':alertType==='C'}" style="width:95%" role="alert"
        v-if="showAlert">
        <i class="fas fa-check me-2"></i> 
        <div v-if="alertType2==='reservation'">
            {{alertType==='C'?'노래를 취소하였습니다':'노래를 예약하였습니다'}}
        </div>
        <div v-else>
            {{alertType==='C'?'즐겨찾기에서 삭제되었습니다':'즐겨찾기에서 추가되었습니다'}}
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            showAlert: false,
            alertType:'',
            alertType2:'',
            selectSong:null
        }
    },
    props:{
        list:{
            type:Array
        }
    },
    methods:{
        delayedAlert(item,boolean) {
            if(boolean){
                this.alertType2 = 'reservation'
                let arr = this.reservation_list;
                if(arr.includes(item)){
                    this.alertType = 'C'
                    let songIndex = arr.findIndex((element) => element === item);
                    this.$store.commit('removeReservation',songIndex)
                }else{
                    this.alertType = 'R'
                    this.$store.commit('reservation',item)
                }
            }else{
                this.alertType2 = 'favorite'
                let arr = this.favorite_list;
                if(arr.includes(item)){
                    this.alertType = 'C'
                    let songIndex = arr.findIndex((element) => element === item);
                    this.$store.commit('removeFavorite',songIndex)
                }else{
                    this.alertType = 'R'
                    this.$store.commit('addFavorite',item)
                }
            }         
            
            this.showAlert = true;
            setTimeout(() => {
                this.showAlert = false;
            }, 2000);
        },
    },
    computed:{
        reservation_list(){
            return this.$store.state.reserved_song
        },
        favorite_list(){
            return this.$store.state.favorite_song
        },
    }
}
</script>

<style lang="scss" scoped>
    .basic_set_list .alert{
        z-index: 100;
    }
    .bg-select{
        background-color: #ff57001a;
    }
</style>